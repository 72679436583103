import { TYPES } from '../stores/app'

import { getSecretaryDoctors } from '../../../@commons/services/secretary.services'

import { getCompleteToken } from '@cuidardigital/commons/services/auth'

import base64 from 'base-64'
import utf8 from 'utf8'

interface ObjectLiteral {
	[key: string]: any
}

export const initUserSession = (
	state: ObjectLiteral,
	dispatch: (param: ObjectLiteral | any) => void
) => {
	try {
		if (state.session.accessToken) return true

		const token = getCompleteToken()
		if (!token) throw Error('Sem token na memoria')
		const bytes = base64.decode(token)
		const { accessToken, user, doctorCurrent } = JSON.parse(utf8.decode(bytes))

		if (user.role !== 'doctor' && user.cpf) {
			// @ts-ignore
			getSecretaryDoctors({ secretaryCpf: user.username })
				.then((response: ObjectLiteral) => {
					if (response.status <= 204) {
						dispatch({ type: TYPES.SET_DOCTOR_LIST, payload: response.data })
						if (response.data[0])
							dispatch({ type: TYPES.SET_DOCTOR_CURRENT, payload: response.data[0] })
					} else if (response.status === 404) {
						dispatch({
							type: TYPES.MESSAGE_ERROR,
							payload:
								'Está TUDO CERTO com seu cadastro, mas no momento você não possui vínculo com algum médico. Por conta disso, você não consegue acessar as demais áreas da plataforma./n'
						})
					}
				})
				.catch(() => {
					if (process.env.NODE_ENV === 'development') console.log('errrrrooooo')
				})
		}
		dispatch({
			type: TYPES.SET_USER_SESSION,
			payload: { accessToken, ...{ ...user, cpf: user.username } }
		})
		dispatch({
			type: TYPES.SET_DOCTOR_CURRENT,
			payload: doctorCurrent
		})
		return true
	} catch (e) {
		if (process.env.NODE_ENV === 'development') console.log('initUserSession', e)
		return false
	}
}
