import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useContext, useState } from 'react'

import { getCompleteToken, logout, setCompleteToken } from '@cuidardigital/commons/services/auth'
import base64 from 'base-64'
import utf8 from 'utf8'
import MessageModal from '../components/Modal'
import { setStorage } from '../functions/storage'
import { HOME } from '../routes'
import { store } from '../stores/app'
import { initUserSession } from '../utils/session'

interface Props {
	[key: string]: any
}

function App({ location }: Props) {
	const { state, dispatch } = useContext(store)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<any>()
	try {
		const values = queryString.parse(location.search)

		if (values && values.param) {
			setCompleteToken(values.param)
			const bytes = base64.decode(values.param)
			const { accessToken, user } = JSON.parse(utf8.decode(bytes))

			const serialized = JSON.stringify({ token: accessToken, user })
			setStorage(serialized)
			navigate('/')
		} else if (getCompleteToken()) {
			// @ts-ignore
			if (initUserSession(state, dispatch)) {
				navigate(HOME)
			}
		}
	} catch (e) {
		if (process.env.NODE_ENV === 'development') {
			console.log('errror', e)
		}
		setMessageToShow({
			title: 'Pedimos desculpa',
			message: JSON.stringify(e),
			buttonText: 'Ok, entendi'
		})
		setShowMessage(true)
		logout()
	}

	return (
		<>
			{showMessage && (
				<MessageModal
					onClose={setShowMessage}
					title={messageToShow?.title}
					message={messageToShow?.message}
					buttonText={messageToShow?.buttonText}
				/>
			)}
		</>
	)
}

export default App
